﻿import DependencyContainer from 'Lib/DependencyContainer';
import UserContext from 'Lib/UserContext';
import NotificationService from 'Lib/Services/NotificationService';
import UserService from 'Lib/Services/UserService';

export const applicationContextKey = DependencyContainer.name;

export function registerDependencies(): DependencyContainer {
    const applicationContext = new DependencyContainer();

    // Contexts
    applicationContext.registerSingleton(UserContext);

    // Services
    applicationContext.registerSingleton(NotificationService);
    applicationContext.registerSingleton(UserService);

    return applicationContext;
}
